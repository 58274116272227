import { Row, Col, Divider, Avatar, List, Button, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TOPIC_ACTIONS } from "../redux/topic/action";
import TopicForm from "../components/forms/Topic";
import { CHAPTER_ACTIONS } from "../redux/chapter/action";

function Topics() {
  const dispatch = useDispatch();
  const { Search } = Input;

  const [subjectsArray, setSubjectsArray] = useState([]);
  const [updateData, setUpdateData] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const [search, setSearch] = useState("");

  const { data, topic, loading } = useSelector(
    (reducers) => reducers.topicReducer
  );

  const loadData = () => {
    dispatch({
      type: TOPIC_ACTIONS.GET_TOPIC,
      payload: {
        queryParams: {
          query: search,
        },
      },
    });

    dispatch({
      type: CHAPTER_ACTIONS.GET_CHAPTER,
      payload: {
        queryParams: {
          page: 1,
          perPage: 10000000,
        },
      },
    });
  };

  useEffect(() => {
    loadData();
  }, [search]);

  useEffect(() => {
    setSubjectsArray(data);
  }, [data]);

  useEffect(() => {
    setUpdateData(topic);
  }, [topic]);

  const handleEdit = (item) => {
    setIsUpdate(true);

    dispatch({
      type: TOPIC_ACTIONS.GET_TOPIC_BY_ID,
      payload: {
        path: {
          topicId: item.id,
        },
      },
    });
  };

  const handleDelete = (item) => {
    dispatch({
      type: TOPIC_ACTIONS.DELETE_TOPIC,
      payload: {
        path: {
          topicId: item.id,
        },
      },
    });
  };

  const handleUpdateComplete = (item) => {
    setIsUpdate(item);
  };

  const onSearch = (e) => setSearch(e.target.value);

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <TopicForm
              updateData={updateData}
              isUpdate={isUpdate}
              isUpdateComplete={handleUpdateComplete}
            />
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Divider orientation="left" plain>
                  Topics
                </Divider>
                <Search
                  placeholder="Search subject"
                  value={search}
                  onChange={onSearch}
                  enterButton
                />
                <List
                  itemLayout="horizontal"
                  dataSource={subjectsArray}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <Button type="link" onClick={() => handleEdit(item)}>
                          Edit
                        </Button>,
                        <Button
                          type="link"
                          style={{ color: "red" }}
                          onClick={() => handleDelete(item)}
                        >
                          Delete
                        </Button>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={
                              item.image?.length ? item.image[0]?.preview : null
                            }
                          />
                        }
                        title={item.name}
                        description={item.designation}
                      />
                      {item.text}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Topics;
