import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SUBJECT_ACTIONS } from "../../redux/subject/action";
import ImageUploader from "../ImageUploader";

const SubjectForm = ({
  updateData = null,
  isUpdate = false,
  isUpdateComplete,
  activeTab,
}) => {
  const [form] = Form.useForm();
  const [flip, setFlip] = useState(false);
  const [imageData, setImageData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateData) {
      form.setFieldsValue({
        name: updateData?.name,
      });
      setImageData(updateData?.image);
    }
    if (!isUpdate) {
      form.setFieldsValue({
        name: "",
      });
    }
  }, [updateData]);

  const onFinish = (values) => {
    if (isUpdate) {
      dispatch({
        type: SUBJECT_ACTIONS.UPDATE_SUBJECT,
        payload: {
          path: {
            subjectId: updateData.id,
          },
          body: {
            name: values.name,
            image: imageData[0]?.key,
          },
        },
      });
      form.setFieldsValue({
        name: "",
      });
      setImageData([]);
      setFlip(!flip);
      isUpdateComplete(!isUpdate);
    } else {
      dispatch({
        type: SUBJECT_ACTIONS.CREATE_SUBJECT,
        payload: {
          body: {
            name: values.name,
            image: imageData[0]?.key,
          },
        },
      });
      form.setFieldsValue({
        name: "",
      });
    }
  };

  return (
    <>
      <Form layout={"vertical"} form={form} onFinish={onFinish}>
        <Form.Item label="Name" name="name">
          <Input placeholder="Input name" />
        </Form.Item>

        <Form.Item label="Upload Avatar" name="imageLink">
          <ImageUploader
            fileData={imageData}
            setFileData={setImageData}
            flip={flip}
          />
        </Form.Item>

        <Form.Item>
          {isUpdate ? (
            <>
              <Button
                type="dashed"
                style={{ backgroundColor: "#f5c542" }}
                htmlType="submit"
              >
                Update
              </Button>
            </>
          ) : (
            <>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default SubjectForm;
