import axiosClient from "../client";
import { CHAPTER_API_ROUTES } from "./constants";
import * as qs from "querystring";

export class ChapterAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getChapters = async ({ queryParams }) => {
    return this.client.get(
      `${CHAPTER_API_ROUTES.GET_CHAPTER}?${qs.stringify(queryParams)}`
    );
  };

  getChapterById = async ({ path }) =>
    await this.client.get(
      CHAPTER_API_ROUTES.GET_CHAPTER_BY_ID.replace(":id", path.chapterId)
    );

  createChapter = async ({ body }) =>
    await this.client.post(CHAPTER_API_ROUTES.CREATE_CHAPTER, body);

  updateChapter = async ({ path, body }) =>
    await this.client.patch(
      CHAPTER_API_ROUTES.UPDATE_CHAPTER.replace(":id", path.chapterId),
      body
    );

  deleteChapter = async ({ path }) => {
    return await this.client.delete(
      `${CHAPTER_API_ROUTES.DELETE_CHAPTER.replace(":id", path.chapterId)}`
    );
  };
}
