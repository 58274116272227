import axiosClient from "../client";
import { TOPIC_API_ROUTES } from "./constants";
import * as qs from "querystring";

export class TopicAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getTopics = async ({ queryParams }) => {
    return this.client.get(
      `${TOPIC_API_ROUTES.GET_TOPIC}?${qs.stringify(queryParams)}`
    );
  };

  getTopicById = async ({ path }) =>
    await this.client.get(
      TOPIC_API_ROUTES.GET_TOPIC_BY_ID.replace(":id", path.topicId)
    );

  createTopic = async ({ body }) =>
    await this.client.post(TOPIC_API_ROUTES.CREATE_TOPIC, body);

  updateTopic = async ({ path, body }) =>
    await this.client.patch(
      TOPIC_API_ROUTES.UPDATE_TOPIC.replace(":id", path.topicId),
      body
    );

  deleteTopic = async ({ path }) => {
    return await this.client.delete(
      `${TOPIC_API_ROUTES.DELETE_TOPIC.replace(":id", path.topicId)}`
    );
  };
}
