import axiosClient from "../client";
import { CLASS_API_ROUTES } from "./constants";
import * as qs from "querystring";

export class ClassAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getClass = async ({ queryParams }) => {
    return this.client.get(
      `${CLASS_API_ROUTES.GET_CLASS}?${qs.stringify(queryParams)}`
    );
  };

  getClassById = async ({ path }) =>
    await this.client.get(
      CLASS_API_ROUTES.GET_CLASS_BY_ID.replace(":id", path.classId)
    );

  createClass = async ({ body }) =>
    await this.client.post(CLASS_API_ROUTES.CREATE_CLASS, body);

  updateClass = async ({ path, body }) =>
    await this.client.patch(
      CLASS_API_ROUTES.UPDATE_CLASS.replace(":id", path.classId),
      body
    );

  deleteClass = async ({ path }) => {
    return await this.client.delete(
      `${CLASS_API_ROUTES.DELETE_CLASS.replace(":id", path.classId)}`
    );
  };
}
