import { notification } from "antd";
import { all, put, takeEvery } from "redux-saga/effects";
import { doApiCall } from "../helper";
import { CLASS_ACTIONS } from "./action";
import { ClassAPIService } from "../../services/class/classes";

const classService = new ClassAPIService();

export function* GET_CLASS({ payload }) {
  const response = yield doApiCall(
    classService.getClass,
    payload,
    CLASS_ACTIONS.SET_STATE
  );

  if (response) {
    yield put({
      type: CLASS_ACTIONS.SET_STATE,
      payload: {
        data: response.data,
      },
    });
  }
}

export function* CREATE_CLASS({ payload }) {
  const response = yield doApiCall(
    classService.createClass,
    payload,
    CLASS_ACTIONS.SET_STATE
  );

  if (response) {
    const { data } = response;
    yield put({
      type: CLASS_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({
      message: "Class added successfully",
    });
  }

  yield put({
    type: CLASS_ACTIONS.GET_CLASS,
    payload: {
      queryParams: {
        type: payload.type,
      },
    },
  });
}

export function* GET_CLASS_BY_ID({ payload }) {
  const response = yield doApiCall(
    classService.getClassById,
    payload,
    CLASS_ACTIONS.SET_STATE
  );
  if (response) {
    yield put({
      type: CLASS_ACTIONS.SET_STATE,
      payload: {
        class: response,
      },
    });
  }
}

export function* UPDATE_CLASS({ payload }) {
  const response = yield doApiCall(
    classService.updateClass,
    payload,
    CLASS_ACTIONS.SET_STATE
  );

  if (response) {
    const { data } = response;
    yield put({
      type: CLASS_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({
      message: "Class updated successfully",
    });
  }

  yield put({
    type: CLASS_ACTIONS.GET_CLASS,
    payload: {
      queryParams: {
        type: payload.type,
      },
    },
  });
}

export function* DELETE_CLASS({ payload }) {
  const response = yield doApiCall(
    classService.deleteClass,
    payload,
    CLASS_ACTIONS.SET_STATE
  );
  if (response) {
    yield put({
      type: CLASS_ACTIONS.GET_CLASS,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });

    notification.success({
      message: "Class deleted successfully",
    });
  }

  yield put({
    type: CLASS_ACTIONS.GET_CLASS,
    payload: {
      queryParams: {
        type: payload.type,
      },
    },
  });
}

export function* classSaga() {
  yield all([
    takeEvery(CLASS_ACTIONS.GET_CLASS, GET_CLASS),
    takeEvery(CLASS_ACTIONS.CREATE_CLASS, CREATE_CLASS),
    takeEvery(CLASS_ACTIONS.GET_CLASS_BY_ID, GET_CLASS_BY_ID),
    takeEvery(CLASS_ACTIONS.UPDATE_CLASS, UPDATE_CLASS),
    takeEvery(CLASS_ACTIONS.DELETE_CLASS, DELETE_CLASS),
  ]);
}
