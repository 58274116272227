import { notification } from "antd";
import { all, put, takeEvery } from "redux-saga/effects";
import { doApiCall } from "../helper";
import { TOPIC_ACTIONS } from "./action";
import { TopicAPIService } from "../../services/topic/topic";

const topicService = new TopicAPIService();

export function* GET_TOPIC({ payload }) {
  const response = yield doApiCall(
    topicService.getTopics,
    payload,
    TOPIC_ACTIONS.SET_STATE
  );

  if (response) {
    yield put({
      type: TOPIC_ACTIONS.SET_STATE,
      payload: {
        data: response.data,
      },
    });
  }
}

export function* CREATE_TOPIC({ payload }) {
  const response = yield doApiCall(
    topicService.createTopic,
    payload,
    TOPIC_ACTIONS.SET_STATE
  );

  if (response) {
    const { data } = response;
    yield put({
      type: TOPIC_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({
      message: "Topic added successfully",
    });
  }

  yield put({
    type: TOPIC_ACTIONS.GET_TOPIC,
    payload: {
      queryParams: {
        type: payload.type,
      },
    },
  });
}

export function* GET_TOPIC_BY_ID({ payload }) {
  const response = yield doApiCall(
    topicService.getTopicById,
    payload,
    TOPIC_ACTIONS.SET_STATE
  );
  if (response) {
    yield put({
      type: TOPIC_ACTIONS.SET_STATE,
      payload: {
        topic: response,
      },
    });
  }
}

export function* UPDATE_TOPIC({ payload }) {
  const response = yield doApiCall(
    topicService.updateTopic,
    payload,
    TOPIC_ACTIONS.SET_STATE
  );

  if (response) {
    const { data } = response;
    yield put({
      type: TOPIC_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({
      message: "Topic updated successfully",
    });
  }

  yield put({
    type: TOPIC_ACTIONS.GET_TOPIC,
    payload: {
      queryParams: {
        type: payload.type,
      },
    },
  });
}

export function* DELETE_TOPIC({ payload }) {
  const response = yield doApiCall(
    topicService.deleteTopic,
    payload,
    TOPIC_ACTIONS.SET_STATE
  );
  if (response) {
    yield put({
      type: TOPIC_ACTIONS.GET_TOPIC,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });

    notification.success({
      message: "Topic deleted successfully",
    });
  }

  yield put({
    type: TOPIC_ACTIONS.GET_TOPIC,
    payload: {
      queryParams: {
        type: payload.type,
      },
    },
  });
}

export function* topicSaga() {
  yield all([
    takeEvery(TOPIC_ACTIONS.GET_TOPIC, GET_TOPIC),
    takeEvery(TOPIC_ACTIONS.CREATE_TOPIC, CREATE_TOPIC),
    takeEvery(TOPIC_ACTIONS.GET_TOPIC_BY_ID, GET_TOPIC_BY_ID),
    takeEvery(TOPIC_ACTIONS.UPDATE_TOPIC, UPDATE_TOPIC),
    takeEvery(TOPIC_ACTIONS.DELETE_TOPIC, DELETE_TOPIC),
  ]);
}
